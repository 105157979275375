import { useState, useRef, useEffect, KeyboardEvent } from 'react';
import Fuse from 'fuse.js';
import { Feature } from 'geojson';
import { useTranslation } from 'react-i18next';

export function FuzzySearch(props: {
    data: Feature[];
    onSelect: (id: string) => void;
    searchKey: string;
    selectedFeatures: string[];
}) {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [results, setResults] = useState<Feature[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const fuse = useRef(
        new Fuse(props.data, {
            keys: [`properties.${props.searchKey}`],
            threshold: 0.3,
        })
    );

    useEffect(() => {
        fuse.current = new Fuse(props.data, {
            keys: [`properties.${props.searchKey}`],
            threshold: 0.3,
        });
    }, [props.data, props.searchKey]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
                setActiveIndex(-1);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () =>
            document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSearch = (value: string) => {
        setSearch(value);
        setActiveIndex(-1);
        if (value.length > 1) {
            const searchResults = fuse.current
                .search(value)
                .map((result) => result.item);
            setResults(searchResults.slice(0, 5));
            setIsOpen(true);
        } else {
            setResults([]);
            setIsOpen(false);
        }
    };

    const handleSelect = (feature: Feature, fromKeyboard = false) => {
        const id = feature.properties?.id;
        if (id) {
            props.onSelect(id);
            if (!fromKeyboard) {
                setSearch('');
                setResults([]);
                setIsOpen(false);
                setActiveIndex(-1);
            }
            inputRef.current?.focus();
        }
    };

    const clearSearch = () => {
        setSearch('');
        setResults([]);
        setIsOpen(false);
        setActiveIndex(-1);
        inputRef.current?.focus();
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (!isOpen) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setActiveIndex((prev) => {
                    if (prev === -1) return 0;
                    return prev < results.length - 1 ? prev + 1 : prev;
                });
                break;
            case 'ArrowUp':
                e.preventDefault();
                setActiveIndex((prev) => {
                    if (prev === -1) return results.length - 1;
                    return prev > 0 ? prev - 1 : prev;
                });
                break;
            case 'Enter':
                e.preventDefault();
                if (activeIndex >= 0 && results[activeIndex]) {
                    handleSelect(results[activeIndex], true);
                }
                break;
            case 'Escape':
                e.preventDefault();
                clearSearch();
                break;
        }
    };

    return (
        <div 
            className="dropdown dropdown-top" 
            ref={dropdownRef}
            role="combobox"
            aria-expanded={isOpen}
            aria-haspopup="listbox"
            aria-controls="search-results"
        >
            <div className="relative">
                <input
                    ref={inputRef}
                    type="text"
                    placeholder={t('search-area')!}
                    className="input input-sm input-bordered w-48 pr-8"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    onFocus={() => search.length > 1 && setIsOpen(true)}
                    onKeyDown={handleKeyDown}
                    aria-autocomplete="list"
                    aria-controls="search-results"
                    aria-activedescendant={
                        activeIndex >= 0 ? `result-${activeIndex}` : undefined
                    }
                />
                {search && (
                    <button
                        className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                        onClick={clearSearch}
                        aria-label={t('rensa')!}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}
            </div>
            {isOpen && results.length > 0 && (
                <ul 
                    id="search-results"
                    role="listbox"
                    className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-56 max-h-60 overflow-y-auto translate-y-[-0.5rem]"
                    aria-label={t('search-area')!}
                >
                    {results.map((result, index) => {
                        const isSelected = props.selectedFeatures.includes(result.properties?.id);
                        const isActive = index === activeIndex;
                        return (
                            <li 
                                key={result.properties?.id}
                                role="option"
                                aria-selected={isActive}
                                id={`result-${index}`}
                            >
                                <button
                                    onClick={() => handleSelect(result)}
                                    onMouseEnter={() => setActiveIndex(index)}
                                    className={`
                                        ${isActive ? 'bg-primary text-primary-content' : ''}
                                        ${isSelected ? 'font-bold' : ''}
                                        flex justify-between items-center w-full hover:bg-primary hover:text-primary-content
                                    `}
                                >
                                    {result.properties?.[props.searchKey]}
                                    <span className={` w-4 ${isActive ? 'text-primary-content' : 'text-primary'}`}>
                                        {isSelected ? '✓' : ''}
                                    </span>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}
