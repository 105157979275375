import { useFeature } from 'flagged';
import { useTranslation } from 'react-i18next';
import { DataOptions, GeoLevel, GeoLevelData, TimeRangeMap } from '../../types';

type Props = {
    geoLevel: GeoLevel;
    vertical?: boolean;
    dataOptions: DataOptions;
    timeRange: TimeRangeMap;
    geoLevels: GeoLevelData[];
    onSetGeoLevel: (geoLevel: GeoLevel) => void;
};

export function GeoLevelPicker({
    geoLevel,
    vertical = false,
    dataOptions,
    timeRange,
    geoLevels,
    onSetGeoLevel,
}: Props) {
    const { t } = useTranslation();

    const ffUserLogin = useFeature('USER_LOGIN');

    return (
        <div
            className={`tabs ${vertical ? 'tabs-xs' : 'tabs-sm'} tabs-boxed ${
                vertical && 'tooltip tooltip-right'
            }`}
            data-tip={t('valj-geografisk-indelning')}
        >
            {geoLevels
                .filter((level) => {
                    if (level.loggedIn) {
                        return ffUserLogin;
                    }
                    return true;
                })
                .map((level) => {
                    const disabled = Object.values(
                        dataOptions.indicators
                    ).reduce((acc, curr) => {
                        if (!curr) return acc;
                        return acc || !timeRange[curr.id][level.id];
                    }, false);
                    return (
                        <button
                            key={level.id}
                            disabled={disabled}
                            className={`
                                tab
                                 ${geoLevel === level.id && 'tab-active'} 
                                 ${
                                     disabled
                                         ? 'opacity-50 cursor-not-allowed line-through tab-disabled'
                                         : ''
                                 }
                                `}
                            style={{
                                color: level.loggedIn ? 'oklch(var(--wa))' : '',
                            }}
                            onClick={() => {
                                onSetGeoLevel(level.id);
                            }}
                        >
                            {vertical ? level.name.charAt(0) : level.name}
                        </button>
                    );
                })}
        </div>
    );
}
