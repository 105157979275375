import { SVGProps } from 'react';

interface ColorIconProps extends SVGProps<SVGSVGElement> {
    size?: number;
}

export function ColorIcon({ size = 24, ...props }: ColorIconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            {...props}
        >
            <g transform="translate(0, -1)">
                <circle cx="7" cy="18" r="6" fill="#E74C3C" />
                <circle cx="17" cy="18" r="6" fill="#2ECC71" />
                <circle cx="12" cy="9" r="6" fill="#FFC107" />
            </g>
        </svg>
    );
}
