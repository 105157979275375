import { createFileRoute, redirect } from '@tanstack/react-router';
import { FEATURES } from '../constants';
import { searchSchemaOptional } from '../zod';

export const Route = createFileRoute('/login/')({
    component: Index,
    loader: async () => {
        if (!FEATURES.USER_LOGIN) {
            redirect({
                to: '/',
                search: searchSchemaOptional.parse({}),
                throw: true,
            });
        }

        const response = await fetch(
            `${import.meta.env.VITE_API_URL}/admin/login/check`,
            {
                method: 'GET',
                credentials: 'include',
            }
        );

        if (response.ok) {
            redirect({
                to: '/',
                search: searchSchemaOptional.parse({}),
                throw: true,
            });
        }
    },
});

function GitHubLoginButton() {
    const loginWithGitHub = async () => {
        fetch(`${import.meta.env.VITE_API_URL}/admin/login/github`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                const redirectURI = `${
                    import.meta.env.VITE_API_URL
                }/admin/login/github/callback/redirect`;
                window.location.href = `${data.url}&redirect_uri=${redirectURI}`;
            });
    };

    return (
        <button className="btn" onClick={loginWithGitHub}>
            Login with GitHub
        </button>
    );
}

function HuddingeLoginButton() {
    const loginWithHuddinge = async () => {
        window.location.href = `${
            import.meta.env.VITE_API_URL
        }/admin/login/huddinge?to=client`;
    };

    return (
        <button className="btn" onClick={loginWithHuddinge}>
            Login with Huddinge
        </button>
    );
}

function Index() {
    return (
        <div className="border-2 h-full grid items-center">
            <div></div>

            <div className="flex items-center justify-center h-screen bg-base-200">
                <div className="card w-96 shadow-2xl bg-base-100">
                    <div className="card-body">
                        <h2 className="card-title justify-center text-xl font-bold">
                            Login
                        </h2>
                        {FEATURES.GITHUB_LOGIN && <GitHubLoginButton />}
                        {FEATURES.HUDDINGE_LOGIN && <HuddingeLoginButton />}
                    </div>
                </div>
            </div>
        </div>
    );
}
