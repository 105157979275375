import { useTranslation } from 'react-i18next';

type Props = {};

export function LanguageSwitch({}: Props) {
    const { t, i18n } = useTranslation();

    const languages = ['sv', 'en'];

    function setLanguage(newLang: string) {
        i18n.changeLanguage(newLang);
    }

    return (
        <div
            className={`tabs tabs-boxed`}
            data-tip={t('switch-language')}
        >
            {languages.map((lang) => {
                return (
                    <button
                        key={lang}
                        onClick={() => setLanguage(lang)}
                        className={`tab ${
                            i18n.language === lang && 'tab-active'
                        }`}
                    >
                        {lang}
                    </button>
                );
            })}
        </div>
    );
}
