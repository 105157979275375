import { ApiConfig } from './types';

export const configLoader = async (loggedIn = false) => {
    let url = import.meta.env.VITE_API_URL + '/config.json'
    if(loggedIn){
        url += `?cacheBuster=loggedIn`
    }
    const response = await fetch(
        url,
        {
            credentials: 'include',
        }
    );
    const json = await response.json();
    return {
        config: json as ApiConfig,
    };
};
