import { scaleLinear, scaleQuantile } from 'd3-scale';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { ColorType, ScaleType, colorScales } from '../../hooks/use-colorscale';
import { useToolStore } from './tool.store';


const defaultColorType = 'sequential';
const defaultScaleType = 'naturalbreaks';

type ScaleTypeOptions = {
    colorScale: string;
    scaleType: ScaleType;
    colorType?: ColorType;
};

type Props = {};

export function ColorSettings(props: Props) {
    const { t } = useTranslation();

    const toolStore = useToolStore(
        useShallow((state) => ({
            colorScheme: state.colorScheme,
            setColorScheme: state.setColorScheme,
            scaleType: state.scaleType,
            setScaleType: state.setScaleType,
            colorType: state.colorType,
            setColorType: state.setColorType,
        }))
    );

    const handleScaleChange = (options: ScaleTypeOptions) => {
        toolStore.setColorScheme(options.colorScale);
        toolStore.setScaleType(options.scaleType);
        toolStore.setColorType(options.colorType ?? 'sequential');
    };

    // const handleResetScale = () => {
    //     // setActiveColorScale(null);
    //     // navigate({
    //     //     replace: true,
    //     //     search: {
    //     //         ...search,
    //     //         scaleType: undefined,
    //     //         colorType: undefined,
    //     //         colorScale: undefined,
    //     //     },
    //     // });
    // };

    return (
        <div className="flex flex-col gap-3">
            <div className="flex gap-3">
                <h3 className="capitalize">{t('fargschema')}</h3>
                {/* {(search.colorType !== defaultColorType ||
                    search.scaleType !== defaultScaleType) && (
                    <button
                        className="btn btn-xs btn-warning"
                        onClick={handleResetScale}
                    >
                        {t('reset')}
                    </button>
                )} */}
            </div>
            <div className="flex gap-2">
                <div className="flex flex-col gap-1">
                    <ScaleTypeButton
                        colorScale="interpolateSpectral"
                        scaleType="linear"
                        colorType="diverging"
                        isActive={toolStore.colorScheme === 'interpolateSpectral'}
                        onScaleChange={handleScaleChange}
                    />
                    <ScaleTypeButton
                        colorScale="interpolateBrBG"
                        scaleType="linear"
                        colorType="diverging"
                        isActive={toolStore.colorScheme === 'interpolateBrBG'}
                        onScaleChange={handleScaleChange}
                    />
                    <ScaleTypeButton
                        colorScale="interpolateRdBu"
                        scaleType="linear"
                        colorType="diverging"
                        isActive={toolStore.colorScheme ==='interpolateRdBu'}
                        onScaleChange={handleScaleChange}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <ScaleTypeButton
                        colorScale="interpolateYlOrBr"
                        scaleType="linear"
                        colorType="sequential"
                        isActive={toolStore.colorScheme === 'interpolateYlOrBr'}
                        onScaleChange={handleScaleChange}
                    />
                    <ScaleTypeButton
                        colorScale="interpolateGnBu"
                        scaleType="linear"
                        colorType="sequential"
                        isActive={toolStore.colorScheme === 'interpolateGnBu'}
                        onScaleChange={handleScaleChange}
                    />
                    <ScaleTypeButton
                        colorScale="interpolateViridis"
                        scaleType="linear"
                        colorType="sequential"
                        isActive={toolStore.colorScheme === 'interpolateViridis'}
                        onScaleChange={handleScaleChange}
                    />
                </div>
                <div className="flex flex-col gap-1">
                    <ScaleTypeButton
                        colorScale="schemeYlOrBr"
                        scaleType="naturalbreaks"
                        isActive={toolStore.colorScheme === 'schemeYlOrBr'}
                        onScaleChange={handleScaleChange}
                    />
                    <ScaleTypeButton
                        colorScale="schemeGnBu"
                        scaleType="naturalbreaks"
                        isActive={toolStore.colorScheme === 'schemeGnBu'}
                        onScaleChange={handleScaleChange}
                    />
                    <ScaleTypeButton
                        colorScale="schemeRdPu"
                        scaleType="naturalbreaks"
                        isActive={toolStore.colorScheme === 'schemeRdPu'}
                        onScaleChange={handleScaleChange}
                    />
                </div>
            </div>
        </div>
    );
}

function ScaleTypeButton(props: {
    colorScale: string;
    scaleType: ScaleType;
    colorType?: ColorType;
    isActive: boolean;
    onScaleChange: (options: ScaleTypeOptions) => void;
}) {
    const colorScale = colorScales[props.colorScale];

    return (
        <button
            className={`border-2 rounded-md overflow-hidden ${
                props.isActive ? 'border-base-300' : 'border-transparent'
            }`}
            onClick={() => {
                props.onScaleChange({
                    colorScale: props.colorScale,
                    scaleType: props.scaleType,
                    colorType: props.colorType,
                });
            }}
        >
            <div className="max-w-fit">
                {props.scaleType === 'linear' && (
                    <SVGGradient
                        x={10}
                        y={10}
                        width={50}
                        height={20}
                        colorInterpolator={colorScale}
                    />
                )}
                {props.scaleType === 'naturalbreaks' && (
                    <SVGClasses
                        x={10}
                        y={10}
                        width={50}
                        height={20}
                        scheme={colorScale[5]}
                    />
                )}
            </div>
        </button>
    );
}

function SVGClasses(props: {
    width: number;
    height: number;
    x: number;
    y: number;
    scheme: readonly string[];
}) {
    const x = scaleLinear()
        .domain([0, props.scheme.length - 1])
        .range([0, 1]);

    const cScale = scaleQuantile(props.scheme).domain([0, 1]);

    return (
        <svg x={props.x} y={props.y} width={props.width} height={props.height}>
            {Array.from(Array(props.scheme.length + 1)).map((_, i) => {
                return (
                    <rect
                        key={i}
                        x={(i * props.width) / props.scheme.length}
                        y={0}
                        height={props.height}
                        fill={cScale(x(i))}
                        width={props.width / props.scheme.length}
                    />
                );
            })}
        </svg>
    );
}

function SVGGradient(props: {
    width: number;
    height: number;
    x: number;
    y: number;
    colorInterpolator: (t: number) => string;
}) {
    const x = scaleLinear().domain([0, props.width]).range([0, 1]);

    return (
        <svg x={props.x} y={props.y} width={props.width} height={props.height}>
            {Array.from(Array(props.width + 1)).map((_, i) => {
                return (
                    <rect
                        key={i}
                        x={i}
                        y={0}
                        height={props.height}
                        fill={props.colorInterpolator(x(i))}
                        width={1}
                    />
                );
            })}
        </svg>
    );
}
