import { mean } from 'd3-array';
import { ScalePower } from 'd3-scale';
import { ZoomTransform } from 'd3-zoom';
import { MapView, OrthographicView } from 'deck.gl';
import { Feature, GeoJsonProperties, Point } from 'geojson';
import { useMemo, useRef } from 'react';
import { featureIsIncludedInCurrentFilter } from '../../helpers/filter';
import {
    CurrentView,
    DataOptions,
    Indicator,
    MapType,
    MeanValuesByYear,
} from '../../types';
import { ZoomInIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { linearRegression, linearRegressionLine } from 'simple-statistics';
import { getRouteApi } from '@tanstack/react-router';
import { useToolStore } from '../Tool/tool.store';
import { ClearSelectionIcon } from '../Icons/ClearSelectionIcon';

const routeApi = getRouteApi('/');

export function ResetZoomButton({ onClick }: { onClick: () => void }) {
    const { t } = useTranslation();
    return (
        <button
            onClick={onClick}
            type="button"
            className="fill-primary w-6 h-6 tooltip tooltip-left"
            data-tip={t('reset-zoom')}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="100%"
                width="100%"
                viewBox="0 0 24 24"
            >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M3 5v4h2V5h4V3H5c-1.1 0-2 .9-2 2zm2 10H3v4c0 1.1.9 2 2 2h4v-2H5v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2V5c0-1.1-.9-2-2-2z" />
            </svg>
        </button>
    );
}

export function MapTypeSelector(props: {}) {
    const { t } = useTranslation();

    const mapType = useToolStore((state) => state.mapType);
    const setMapType = useToolStore((state) => state.setMapType);

    return (
        <div className="tabs tabs-boxed tabs-xs flex flex-wrap justify-center">
            <button
                onClick={() => {
                    setMapType('bubbles');
                }}
                className={`tab capitalize tab-xs static ${
                    mapType === 'bubbles' && 'tab-active'
                }`}
            >
                {t('bubblor')}
            </button>
            <button
                onClick={() => {
                    setMapType('choropleth');
                }}
                className={`tab capitalize tab-xs static  ${
                    mapType === 'choropleth' && 'tab-active'
                }`}
            >
                {t('polygoner')}
            </button>
        </div>
    );
}

export function ClearSelectionButton({ onClick }: { onClick: () => void }) {
    const { t } = useTranslation();

    return (
        <div className="tooltip tooltip-top" data-tip={t('rensa-markering')}>
            <button
                onClick={onClick}
                type="button"
                className="btn btn-sm btn-circle btn-secondary"
        >
                <ClearSelectionIcon size={20} fill="white" />
            </button>
        </div>
    );
}

export function SelectAllButton({ onClick }: { onClick: () => void }) {
    const { t } = useTranslation();

    return (
        <button
            onClick={onClick}
            type="button"
            className="btn btn-xs btn-primary"
        >
            {t('markera-alla')}
        </button>
    );
}

export function ToggleZoomButton({
    onClick,
    active,
}: {
    onClick: () => void;
    active: boolean;
}) {
    return (
        <button
            onClick={onClick}
            type="button"
            className={`btn btn-sm btn-circle btn-primary no-animation`}
        >
            <ZoomInIcon fill={active ? 'rgba(255, 255, 255, 0.4)' : 'hsl(202.7,40%,99%)'} />
        </button>
    );
}

type LabelFeatureOptions = {
    rect: DOMRect | undefined;
    dataFeatures: Feature<Point, GeoJsonProperties>[];
    selectedFeatures: string[];
    fullScatter: boolean;
    years: number[];
    xScale: ScalePower<number, number, never>;
    xVar: Indicator;
    yScale: ScalePower<number, number, never>;
    yVar: Indicator;
    rScaleScatter: ScalePower<number, number, never>;
    rVar: Indicator;
    zoomToFiltered: boolean;
    transform: ZoomTransform;
    view: CurrentView;
};

type MeanValueOptions = {
    dataFeatures: Feature<Point, GeoJsonProperties>[];
    featureFilter: string[];
    xVar: Indicator;
    yVar: Indicator;
    rVar: Indicator;
    cVar: Indicator | null;
    years: number[];
};

export function useMeanValuesByYear(options: MeanValueOptions) {
    const { dataFeatures, xVar, yVar, rVar, cVar, years, featureFilter } =
        options;
    return useMemo(() => {
        const _filtered = dataFeatures.filter((f) =>
            featureIsIncludedInCurrentFilter(f, featureFilter)
        );

        const _meanValues: MeanValuesByYear = {
            [xVar.id]: {},
            [yVar.id]: {},
            [rVar.id]: {},
        };

        if (cVar) {
            _meanValues[cVar.id] = {};
        }

        for (const year of years) {
            const values = _filtered.map((f) => {
                const v = {
                    [xVar.id]: f.properties?.values[xVar.id][year],
                    [yVar.id]: f.properties?.values[yVar.id][year],
                    [rVar.id]: f.properties?.values[rVar.id][year],
                };
                if (cVar) {
                    v[cVar.id] = f.properties?.values[cVar.id][year];
                }

                return v;
            });

            const xMean = mean(values, (d: any) => d[xVar.id]);
            _meanValues[xVar.id][year] = xMean ?? null;

            const yMean = mean(values, (d: any) => d[yVar.id]);
            _meanValues[yVar.id][year] = yMean ?? null;

            const rMean = mean(values, (d: any) => d[rVar.id]);
            _meanValues[rVar.id][year] = rMean ?? null;

            if (cVar) {
                const cMean = mean(values, (d: any) => d[cVar.id]);
                _meanValues[cVar.id][year] = cMean ?? null;
            }
        }
        return _meanValues;
    }, [dataFeatures, years, xVar, yVar, rVar, cVar]);
}

type RegressionLineOptions = {
    dataFeatures: Feature<Point, GeoJsonProperties>[];
    featureFilter: string[];
    xVar: Indicator;
    yVar: Indicator;
    years: number[];
};

export function useRegressionLine(options: RegressionLineOptions) {
    const { dataFeatures, xVar, yVar, years, featureFilter } = options;
    return useMemo(() => {
        const _filtered = dataFeatures.filter((f) =>
            featureIsIncludedInCurrentFilter(f, featureFilter)
        );

        const values: number[][] = [];

        for (const year of years) {
            _filtered.forEach((f) => {
                const v: number[] = [
                    f.properties?.values[xVar.id][year],
                    f.properties?.values[yVar.id][year],
                ];

                values.push(v);
            });
        }

        return linearRegressionLine(linearRegression(values));
    }, [dataFeatures, years, xVar, yVar]);
}

export function featureHasNullValuesForYear(
    d: Feature,
    options: DataOptions,
    year: number
) {
    const { xVar, yVar, rVar, cVar } = options.indicators;
    // If any value is null for the current year, return totally transparent color.
    // A way to filter out null values
    const values = [
        d.properties?.values[xVar.id][year],
        d.properties?.values[yVar.id][year],
        d.properties?.values[rVar.id][year],
    ];
    if (cVar) {
        values.push(d.properties?.values[cVar.id][year]);
    }
    return values.some((v) => v === null);
}

export function getViews(
    currentView?: CurrentView,
    controller: boolean = true
) {
    switch (currentView) {
        case 'bubble_and_map': {
            return [
                new OrthographicView({
                    id: 'ortho',
                    controller,
                    width: '50%',
                }),
                new MapView({
                    id: 'map',
                    controller,
                    width: '50%',
                    x: '50%',
                }),
            ];
        }
        case 'bubble': {
            return [
                new OrthographicView({
                    id: 'ortho',
                    controller,
                }),
            ];
        }
        case 'map': {
            return [
                new MapView({
                    id: 'map',
                    controller,
                }),
            ];
        }
    }
}
