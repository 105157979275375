import { Link } from '@tanstack/react-router';
import { useTitle } from '../../hooks/use-title.hook';
import { BookIcon } from '../Icons/BookIcon';
import { LinkExternal } from '../LinkExternal';
import { ScrollyArticle } from './ScrollyArticle';
import { Trans, useTranslation } from 'react-i18next';
import { OpenInNewIcon } from '../Icons/OpenInNewIcon';
import { PlayIcon } from '../Icons/PlayIcons';

export function Introduction() {
    const { t } = useTranslation(['translation', 'introduction']);

    useTitle(t('title', { ns: 'introduction' }));

    return (
        <ScrollyArticle>
            <div className="prose mx-auto">
                <h1>{t('header', { ns: 'introduction' })}</h1>

                <p>{t('p1', { ns: 'introduction' })}</p>

                <p>{t('p2', { ns: 'introduction' })}</p>

                <p>
                    <Trans t={t} i18nKey={'p3'} ns="introduction">
                        see
                        <Link
                            onClick={() => window.scrollTo(0, 0)}
                            to="/om/guide"
                            className="link"
                        >
                            guide
                        </Link>{' '}
                        to tool
                    </Trans>
                </p>

                {/* Youtube thumbnail link */}
                <div className="grid place-items-center">
                    <a
                        title="Link to youtube video: Next gen visual stats for cities - Statsmap on workdrain and segregation"
                        href="https://www.youtube.com/watch?v=0mElY3bN-Sg"
                        target="_blank"
                        className="grid place-items-center"
                    >
                        <div className="col-span-full row-span-full">
                            <img
                                className="rounded-xl"
                                src="https://img.youtube.com/vi/0mElY3bN-Sg/hqdefault.jpg"
                            />
                        </div>
                        <div className="col-span-full row-span-full z-10">
                            <div className="btn btn-primary shadow-2xl fill-white">
                                <PlayIcon />
                                <OpenInNewIcon fill="white" size={16} />
                            </div>
                        </div>
                    </a>
                </div>
                <p>
                    <Trans t={t} i18nKey={'background'} ns="introduction">
                        aaa
                        <LinkExternal href="https://sodertornsmodellen.com/sodertornsanalysen">
                            bbb
                        </LinkExternal>
                        <LinkExternal href="https://www.gapminder.org/">
                            ddd
                        </LinkExternal>
                        eee
                    </Trans>
                </p>

                <h2>{t('stories', { ns: 'introduction' })}</h2>
                <p>{t('p4', { ns: 'introduction' })}</p>
                <ul className="list-none p-0">
                    <li>
                        <Link
                            onClick={() => window.scrollTo(0, 0)}
                            to="/om/socioekonomi"
                            className="flex items-center gap-2 link"
                        >
                            <BookIcon className="fill-info" />
                            {t('socioeconomy')}
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => window.scrollTo(0, 0)}
                            to="/om/lyckoparadoxen"
                            className="flex items-center gap-2 link"
                        >
                            <BookIcon className="fill-warning" />
                            {t('happinessParadox')}
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => window.scrollTo(0, 0)}
                            to="/om/bostader"
                            className="flex items-center gap-2 link"
                        >
                            <BookIcon className="fill-secondary" />
                            {t('housing')}
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => window.scrollTo(0, 0)}
                            to="/om/barn-utbildning"
                            className="flex items-center gap-2 link"
                        >
                            <BookIcon className="fill-error" />
                            {t('childrenEducation')}
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => window.scrollTo(0, 0)}
                            to="/om/kon-inkomst"
                            className="flex items-center gap-2 link"
                        >
                            <BookIcon className="fill-primary" />
                            {t('genderIncome')}
                        </Link>
                    </li>
                </ul>

                <div className="prose mt-24 px-6 py-3 sm:-ml-6 sm:-mr-6 card bg-info text-white shadow-xl">
                    <p>{t('p5', { ns: 'introduction' })}</p>
                    <ul>
                        <li>
                            <LinkExternal
                                color="white"
                                href="https://www.huddinge.se/op2050"
                            >
                                Huddinges översiktsplan
                            </LinkExternal>
                        </li>
                        <li>
                            <LinkExternal
                                color="white"
                                href="https://www.huddinge.se/globalassets/ovriga-webbar/verksamhetswebbar/oversiktsplan/dokument-oversiktsplan/op-2050-social-hallbarhet-fysisk-planering-i-huddinge-.pdf"
                            >
                                Social hållbarhet i fysisk planering i Huddinge
                            </LinkExternal>
                        </li>
                        <li>
                            <LinkExternal
                                color="white"
                                href="https://www.huddinge.se/globalassets/huddinge.se/organisation-och-styrning/sa-arbetar-vi-med/hallbar-utveckling/jamlikhetsanalys-print.pdf"
                            >
                                Huddinges handbok för jämlikhetsanalyser
                            </LinkExternal>
                        </li>
                        <li>
                            <LinkExternal
                                color="white"
                                href="https://www.huddinge.se/globalassets/huddinge.se/stadsplanering-och-trafik/for-dig-som-byggaktor-eller-utforare/barnkonsekvensanalys/barnkonsekvensanalys---metodhandbok.pdf"
                            >
                                Metodhandbok för barnkonsekvensanalyser (BKA)
                            </LinkExternal>
                        </li>
                    </ul>
                </div>
            </div>
        </ScrollyArticle>
    );
}

export default Introduction;
