import Fuse from 'fuse.js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeoLevel, Indicator, TimeRange, TimeRangeMap } from '../../types';
import { getMinimumTimerange } from '../../get-minimumtimerange';
import { useToolStore } from '../Tool/tool.store';
import { isTimeRangeOverlapping } from '../../isTimeRangeOverlapping';
import { useIsLoggedIn } from '../AuthProvider';

type EnabledStatus =
    | {
          isDisabled: false;
      }
    | {
          isDisabled: true;
          disabledReason: 'geoLevelUnavailable' | 'timeRangeUnavailable';
      };

function getIndicatorEnabledStatus(options: {
    indicator: Indicator;
    timeRangeMap: TimeRangeMap;
    geoLevel: GeoLevel;
    activeTimeRange: TimeRange['timeRange'];
}): EnabledStatus {
    let disabled = false;

    const hasGeoLevel = !!options.timeRangeMap[options.indicator.id][options.geoLevel]
    if(!hasGeoLevel){
        return {
            isDisabled: true,
            disabledReason: 'geoLevelUnavailable'
        }
    }

    const timeRange = getMinimumTimerange({
        indicators: [options.indicator],
        geoLevel: options.geoLevel,
        timeRangeMap: options.timeRangeMap,
    });

    if (!timeRange) {
        return {
            isDisabled: true,
            disabledReason: 'timeRangeUnavailable',
        };
    }

    disabled = !isTimeRangeOverlapping(options.activeTimeRange, timeRange);

    if (disabled) {
        return {
            isDisabled: disabled,
            disabledReason: 'timeRangeUnavailable',
        };
    } else {
        return {
            isDisabled: disabled,
        };
    }
}

type SearchableIndicator = {
    id: string;
    category: string;
    label: string;
    subLabel: string;
    original: Indicator;
};

function CategoryList(props: {
    categories: Map<string, string>;
    activeCategory: string;
    activeIndicator: Indicator;
    onCategoryClick: (category: string) => void;
}) {
    const { i18n } = useTranslation();
    return (
        <ul className="menu bg-base-100 w-full rounded-box">
            {Array.from(props.categories.entries())
                .sort((a, b) => {
                    return a[1].localeCompare(b[1], i18n.language);
                })
                .map((category) => {
                    return (
                        <li key={category[1]}>
                            <button
                                className={`
                            w-full text-left flex items-center
                            ${
                                props.activeCategory === category[0]
                                    ? 'active'
                                    : ''
                            }
                        `}
                                onClick={() =>
                                    props.onCategoryClick(category[0])
                                }
                            >
                                <div className="flex items-center justify-between w-full pr-2">
                                    {category[1]}
                                    {category[1] ===
                                        (props.activeIndicator.category?.[
                                            i18n.language
                                        ] ??
                                            props.activeIndicator.category?.[
                                                'sv'
                                            ]) && (
                                        <div className="w-3 h-3 rounded-full bg-primary ml-2"></div>
                                    )}
                                </div>
                            </button>
                        </li>
                    );
                })}
        </ul>
    );
}

function SearchBar(props: {
    searchTerm: string;
    onSearchChange: (value: string) => void;
}) {
    const { t } = useTranslation();
    return (
        <div className="sticky top-0 z-10">
            <div className="p-2 relative">
                <input
                    type="text"
                    placeholder={t('search') ?? ''}
                    className="input input-bordered input-sm w-full bg-base-100"
                    value={props.searchTerm}
                    onChange={(e) => props.onSearchChange(e.target.value)}
                />
                {props.searchTerm && (
                    <button
                        className="btn btn-ghost btn-xs absolute right-4 top-1/2 -translate-y-1/2"
                        onClick={() => props.onSearchChange('')}
                    >
                        ✕
                    </button>
                )}
            </div>
        </div>
    );
}

function IndicatorList(props: {
    indicators: Indicator[];
    value: string;
    onSelect: (indicator: Indicator) => void;
    status: EnabledStatus;
}) {
    const { i18n } = useTranslation();
    return (
        <ul className="menu menu-xs">
            {props.indicators.map((indicator) => {
                return (
                    <li key={indicator.id} className="ml-4">
                        <button
                            disabled={props.status.isDisabled}
                            onClick={() => props.onSelect(indicator)}
                            className={`
                            ${props.status.isDisabled ? 'opacity-55' : ''}
                            ${props.value === indicator.id ? 'active' : ''}
                        `}
                        >
                            <div className="text-sm">
                                {indicator.subLabel[i18n.language] ??
                                    indicator.subLabel['sv']}
                            </div>
                        </button>
                    </li>
                );
            })}
        </ul>
    );
}

export function IndicatorDialog(props: {
    indicators: Indicator[];
    value: string;
    onChange: (value: string) => void;
    onClose: () => void;
    tooltip: string;
    indicatorsMap: Record<string, Indicator>;
    activeIndicator: Indicator;
}) {
    const { t, i18n } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const getDataOptions = useToolStore((state) => state.getDataOptions);
    const timeRangeMap = useToolStore((state) => state.timeRangeMap);

    const isLoggedIn = useIsLoggedIn();

    const activeTimeRange = getMinimumTimerange({
        geoLevel: getDataOptions().geoLevel,
        indicators: Object.values(getDataOptions().indicators).filter(
            (f) => f
        ) as Indicator[],
        timeRangeMap,
    });

    // Convert indicators to searchable format
    const searchableIndicators: SearchableIndicator[] = props.indicators.map(
        (indicator) => ({
            id: indicator.id,
            category:
                indicator.category?.[i18n.language] ??
                indicator.category?.['sv'] ??
                '',
            label: indicator.label[i18n.language] ?? indicator.label['sv'],
            subLabel:
                indicator.subLabel[i18n.language] ??
                indicator.subLabel['sv'] ??
                '',
            original: indicator,
        })
    );

    const fuse = new Fuse(searchableIndicators, {
        keys: ['category', 'label', 'subLabel'],
        threshold: 0.3,
        ignoreLocation: true,
    });

    const getFilteredIndicators = () => {
        if (!searchTerm) return props.indicators;
        const searchResults = fuse.search(searchTerm);
        return searchResults.map((result) => result.item.original);
    };

    let categories: Map<string, string> = new Map();

    getFilteredIndicators()
        .filter((i) => {
            return i.permissionLevel?.includes('public');
        })
        .forEach((indicator) => {
            const category =
                indicator.category?.[i18n.language] ??
                indicator.category?.['sv'];
            if (!category) return;

            categories.set(`public-${category}`, category);
        });

    if (isLoggedIn) {
        getFilteredIndicators()
            .filter((i) => {
                return i.permissionLevel?.includes('user');
            })
            .forEach((indicator) => {
                const category =
                    indicator.category?.[i18n.language] ??
                    indicator.category?.['sv'];
                if (!category) return;

                categories.set(`user-${category}`, category);
            });
    }

    const indicatorsByCategory = getFilteredIndicators().reduce(
        (acc, indicator) => {
            const category =
                indicator.category?.[i18n.language] ??
                indicator.category?.['sv'];
            const label =
                indicator.label[i18n.language] ?? indicator.label['sv'];
            const isPublic = indicator.permissionLevel?.includes('public');
            const categoryId = `${isPublic ? 'public' : 'user'}-${category}`;

            if (category && label) {
                if (!acc[categoryId]) {
                    acc[categoryId] = new Map<string, Indicator[]>();
                }
                if (!acc[categoryId].has(label)) {
                    acc[categoryId].set(label, []);
                }
                acc[categoryId].get(label)!.push(indicator);
            }
            return acc;
        },
        {} as Record<string, Map<string, Indicator[]>>
    );

    const [activeCategory, setActiveCategory] = useState<string>(
        `${
            props.activeIndicator.permissionLevel?.includes('public')
                ? 'public'
                : 'user'
        }-${props.activeIndicator.category?.[i18n.language] || ''}`
    );

    const activeCategoryExists = !!categories.get(activeCategory);

    if (searchTerm && !activeCategoryExists && categories.size > 0) {
        setActiveCategory(Array.from(categories.values())[0]);
    }

    const handleIndicatorSelect = (indicator: Indicator) => {
        props.onChange(indicator.id);
    };

    const dataOptions = getDataOptions();

    if (!activeTimeRange) {
        /**
         * This is mostly to please typescript.
         */
        throw new Error('Failed to get active time range');
    }

    return (
        <div className="modal-box w-11/12 max-w-4xl max-h-[80vh] flex flex-col">
            <form method="dialog">
                <button
                    onClick={props.onClose}
                    className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                >
                    ✕
                </button>
            </form>
            <h3 className="font-bold text-lg mb-4">{t(props.tooltip)}</h3>
            <div className="grid grid-cols-[200px_auto_1fr] flex-1 overflow-hidden">
                <div className="overflow-y-auto min-h-0">
                    <SearchBar
                        searchTerm={searchTerm}
                        onSearchChange={setSearchTerm}
                    />
                    {isLoggedIn ? (
                        <>
                            <div className="text-sm mt-3">{t('open-data')}</div>
                            <hr />
                            <CategoryList
                                categories={
                                    new Map(
                                        [...categories].filter(([key]) =>
                                            key.startsWith('public-')
                                        )
                                    )
                                }
                                activeCategory={activeCategory}
                                activeIndicator={props.activeIndicator}
                                onCategoryClick={setActiveCategory}
                            />
                            <div className="text-sm mt-3">{t('closed-data')}</div>
                            <hr />
                            <CategoryList
                                categories={
                                    new Map(
                                        [...categories].filter(([key]) =>
                                            key.startsWith('user-')
                                        )
                                    )
                                }
                                activeCategory={activeCategory}
                                activeIndicator={props.activeIndicator}
                                onCategoryClick={setActiveCategory}
                            />
                        </>
                    ) : (
                        <CategoryList
                            categories={categories}
                            activeCategory={activeCategory}
                            activeIndicator={props.activeIndicator}
                            onCategoryClick={setActiveCategory}
                        />
                    )}
                </div>
                <div className="divider divider-horizontal ml-0"></div>
                <div className="overflow-y-auto h-full">
                    {indicatorsByCategory[activeCategory] &&
                        Array.from(indicatorsByCategory[activeCategory]).map(
                            ([label, indicators]) => {
                                /**
                                 * This is a tricky one. Should the entire label be disabled if one of the sublabels are? This is how it's been
                                 * previously so I'll keep with that pattern here.
                                 */
                                const status = indicators.reduce(
                                    (accStatus, indicator) => {
                                        const status =
                                            getIndicatorEnabledStatus({
                                                activeTimeRange,
                                                geoLevel: dataOptions.geoLevel,
                                                timeRangeMap,
                                                indicator,
                                            });
                                        if (status.isDisabled) {
                                            accStatus = status;
                                        }

                                        return accStatus;
                                    },
                                    { isDisabled: false } as EnabledStatus
                                );

                                const timeRange = status.isDisabled ? null : getMinimumTimerange({
                                    indicators,
                                    geoLevel: dataOptions.geoLevel,
                                    timeRangeMap,
                                });


                                return (
                                    <div key={label}>
                                        <div className="flex flex-col gap-1">
                                            <div className="font-bold text-lg">
                                                {label}
                                            </div>
                                            <div className="">
                                                {timeRange && (
                                                    <IndicatorTimeRange
                                                        disabled={
                                                            status.isDisabled &&
                                                            status.disabledReason ===
                                                                'timeRangeUnavailable'
                                                        }
                                                        timeRange={timeRange}
                                                    />
                                                )}
                                                {status.isDisabled &&
                                                    status.disabledReason ===
                                                        'geoLevelUnavailable' && (
                                                        <div className="text-xs text-warning">
                                                            <div>
                                                                {t(
                                                                    'dataNotAvailable'
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        <IndicatorList
                                            status={status}
                                            indicators={indicators}
                                            value={props.value}
                                            onSelect={handleIndicatorSelect}
                                        />
                                    </div>
                                );
                            }
                        )}
                </div>
            </div>
        </div>
    );
}

function IndicatorTimeRange(props: {
    disabled: boolean;
    timeRange: TimeRange['timeRange'];
}) {
    return (
        <div
            className={`text-xs text-secondary mb-1 flex gap-1 items-center ${
                props.disabled ? 'text-secondary text-opacity-50' : ''
            }`}
        >
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16px"
                    viewBox="0 0 24 24"
                    width="16px"
                    className={`${
                        props.disabled
                            ? 'fill-secondary-content'
                            : 'fill-secondary'
                    }`}
                >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z" />
                </svg>
            </div>{' '}
            <div>
                {props.timeRange[0]} - {props.timeRange[1]}
            </div>
        </div>
    );
}
