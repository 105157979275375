import { MouseEvent, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/use-clickoutside.hook';
import { Data } from '../../hooks/use-data.hook';
import { GeoLevel, Indicator } from '../../types';
import { DownloadIndicatorButton } from '../DownloadIndicatorButton';
import { File, FileSelect } from '../FileViewer';
import { XAxisIcon, YAxisIcon, SizeIcon, ColorIcon } from '../Icons';
import { Portal } from '../Portal';

import { getRouteApi } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useToolStore } from '../Tool/tool.store';
import { useShallow } from 'zustand/shallow';
import { FileSelect2 } from '../FileViewer/FileSelect2';
import { FEATURES } from '../../constants';

const routeApi = getRouteApi('/');

type TooltipButtonProps = {
    indicator: Indicator;
};
function TooltipButton({ indicator }: TooltipButtonProps) {
    const { i18n } = useTranslation();

    const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
    const [viewerPos, setViewerPos] = useState<{ x: number; y: number }>({
        x: 500,
        y: 500,
    });

    const handleClick = (e: MouseEvent) => {
        const rect = (e.target as HTMLButtonElement).getBoundingClientRect();
        setViewerPos({
            x: rect.left + rect.width + 5,
            y: rect.top + rect.height / 2,
        });
        setViewerIsOpen(!viewerIsOpen);
    };

    const portalDivRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useOnClickOutside(portalDivRef, (e) => {
        setViewerIsOpen(false);
    });

    return (
        <div>
            {viewerIsOpen && (
                <Portal>
                    <div
                        ref={portalDivRef}
                        className="absolute bg-white shadow-xl rounded-lg border-secondary border border-opacity-50 p-3 max-w-md"
                        style={{
                            top: `${viewerPos.y}px`,
                            left: `${viewerPos.x}px`,
                            transform: 'translateY(-50%)',
                            width: '400px',
                        }}
                    >
                        <h2 className="font-bold mb-3 text-lg">
                            {indicator.label?.[i18n.language] ??
                                indicator.label?.['sv']}
                        </h2>
                        {indicator.description && (
                            <p className="mb-3">
                                {' '}
                                {indicator.description?.[i18n.language] ??
                                    indicator.description?.['sv']}
                            </p>
                        )}
                        {(indicator.sourceUrl || indicator.sourceName) && (
                            <p>
                                Källa:{' '}
                                {indicator.sourceUrl ? (
                                    <a
                                        href={indicator.sourceUrl}
                                        target="_blank"
                                        className="link"
                                    >
                                        {indicator.sourceName ??
                                            indicator.sourceUrl}
                                    </a>
                                ) : (
                                    indicator.sourceName
                                )}
                            </p>
                        )}
                    </div>
                </Portal>
            )}
            <button
                ref={buttonRef}
                onClick={handleClick}
                type="button"
                className={`btn btn-xs btn-circle btn-primary text-white ${
                    viewerIsOpen ? 'btn-secondary' : ''
                }`}
            >
                ?
            </button>
        </div>
    );
}

type Props = {
    data: Data;
    indicatorFiles: File[];
    indicatorsMap: Record<string, Indicator>;
    geoLevel: GeoLevel;
    featureFilter: string[];
};

export function VarSelect({
    indicatorFiles,
    data,
    indicatorsMap,
    geoLevel,
    featureFilter,
}: Props) {
    const { t } = useTranslation();

    const toolStore = useToolStore(
        useShallow((state) => ({
            setIndicatorById: state.setIndicatorById,
            getDataOptions: state.getDataOptions,
            indicatorsMap: state.indicatorsMap,
        }))
    );

    const dataOptions = toolStore.getDataOptions();

    return (
        <div className="grid grid-cols-[1fr_auto] gap-2">
            {FEATURES.FILE_SELECT_2 ? (
                <FileSelect2
                    indicators={Object.values(toolStore.indicatorsMap)}
                    value={dataOptions.indicators.yVar.id}
                    label="y"
                    labelIcon={<YAxisIcon />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        toolStore.setIndicatorById({
                            key: 'y',
                            indicatorId: value,
                        });
                    }}
                    tooltip={t('valj-y')}
                />
            ) : (
                <FileSelect
                    files={indicatorFiles}
                    value={dataOptions.indicators.yVar.id}
                    label="y"
                    labelIcon={<YAxisIcon />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        toolStore.setIndicatorById({
                            key: 'y',
                            indicatorId: value,
                        });
                    }}
                    tooltip={t('valj-y')}
                />
            )}

            <div className="flex flex-col gap-1 justify-center">
                <TooltipButton indicator={dataOptions.indicators.yVar} />
                <DownloadIndicatorButton
                    featureFilter={featureFilter}
                    indicator={data.options.indicators.yVar}
                    geoLevel={geoLevel}
                    data={data}
                />
            </div>

            {FEATURES.FILE_SELECT_2 ? (
                <FileSelect2
                    indicators={Object.values(toolStore.indicatorsMap)}
                    value={dataOptions.indicators.xVar.id}
                    label="x"
                    labelIcon={<XAxisIcon />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        toolStore.setIndicatorById({
                            key: 'x',
                            indicatorId: value,
                        });
                    }}
                    tooltip={t('valj-x')}
                />
            ) : (
                <FileSelect
                    files={indicatorFiles}
                    value={dataOptions.indicators.xVar.id}
                    label="x"
                    labelIcon={<XAxisIcon />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        toolStore.setIndicatorById({
                            key: 'x',
                            indicatorId: value,
                        });
                    }}
                    tooltip={t('valj-x')}
                />
            )}

            <div className="flex flex-col gap-1 justify-center">
                <TooltipButton indicator={dataOptions.indicators.xVar} />
                <DownloadIndicatorButton
                    indicator={data.options.indicators.xVar}
                    geoLevel={geoLevel}
                    data={data}
                    featureFilter={featureFilter}
                />
            </div>

            {FEATURES.FILE_SELECT_2 ? (
                <FileSelect2
                    indicators={Object.values(toolStore.indicatorsMap)}
                    value={dataOptions.indicators.rVar.id}
                    label={t('storlek')}
                    labelIcon={<SizeIcon />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        toolStore.setIndicatorById({
                            key: 'r',
                            indicatorId: value,
                        });
                    }}
                    tooltip={t('valj-r')}
                />
            ) : (
                <FileSelect
                    files={[
                        { name: 'Kommun', type: 'file', id: 'kommun' },
                        ...indicatorFiles,
                    ]}
                    value={dataOptions.indicators.yVar.id}
                    label={t('storlek')}
                    labelIcon={<SizeIcon />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        toolStore.setIndicatorById({
                            key: 'r',
                            indicatorId: value,
                        });
                    }}
                    tooltip={t('valj-r')}
                />
            )}

            <div className="flex flex-col gap-1 justify-center">
                <TooltipButton indicator={dataOptions.indicators.rVar} />
                <DownloadIndicatorButton
                    indicator={data.options.indicators.rVar}
                    geoLevel={geoLevel}
                    data={data}
                    featureFilter={featureFilter}
                />
            </div>

            {FEATURES.FILE_SELECT_2 ? (
                <FileSelect2
                    indicators={[
                        // { name: 'Kommun', type: 'file', id: 'kommun' },
                        ...Object.values(toolStore.indicatorsMap),
                    ]}
                    value={
                        dataOptions.indicators.yVar.id
                            ? dataOptions.indicators.yVar.id
                            : 'kommun'
                    }
                    label={t('farg')}
                    labelIcon={<ColorIcon className="drop-shadow-lg" />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        toolStore.setIndicatorById({
                            key: 'c',
                            indicatorId: value === 'kommun' ? null : value,
                        });
                    }}
                    tooltip={t('valj-c')}
                />
            ) : (
                <FileSelect
                    files={[
                        { name: 'Kommun', type: 'file', id: 'kommun' },
                        ...indicatorFiles,
                    ]}
                    value={dataOptions.indicators.yVar.id}
                    label={t('farg')}
                    labelIcon={<ColorIcon className="drop-shadow-lg" />}
                    indicatorsMap={indicatorsMap}
                    onChange={(value) => {
                        toolStore.setIndicatorById({
                            key: 'c',
                            indicatorId: value === 'kommun' ? null : value,
                        });
                    }}
                    tooltip={t('valj-c')}
                />
            )}

            <div className="flex flex-col gap-1 justify-center">
                {dataOptions.indicators.cVar && (
                    <TooltipButton indicator={dataOptions.indicators.cVar} />
                )}
                {dataOptions.indicators.cVar && (
                    <DownloadIndicatorButton
                        featureFilter={featureFilter}
                        indicator={dataOptions.indicators.cVar}
                        geoLevel={geoLevel}
                        data={data}
                    />
                )}
            </div>
        </div>
    );
}
