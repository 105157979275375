import { useState } from 'react';
import { featureIsIncludedInCurrentFilter } from '../../helpers/filter';
import { useDataQuery } from '../../hooks/use-data.hook';
import { ApiConfig, DataOptions, TimeRangeMap, Indicator } from '../../types';
import { FilterFeatures } from '../FilterFeatures';
import { Table } from '../Table';
import { Spinner } from '../Spinner';
import { useTitle } from '../../hooks/use-title.hook';
import { useTranslation } from 'react-i18next';
import { getRouteApi, Link } from '@tanstack/react-router';
import { CloseIcon } from '../Icons/CloseIcon';
import { useToolStore } from '../Tool/tool.store';

const routeApi = getRouteApi('/table/');

type Props = {
    config: ApiConfig;
    indicators: Indicator[];
};

export function TablePage(props: Props) {
    const { t } = useTranslation();
    const search = routeApi.useSearch();

    const selectedFeatures = useToolStore((state) => state.selectedFeatures);
    const setSelectedFeatures = useToolStore(
        (state) => state.setSelectedFeatures
    );
    const featureFilter = useToolStore((state) => state.featureFilter);
    const setFeatureFilter = useToolStore((state) => state.setFeatureFilter);
    const shouldZoomToFiltered = useToolStore(
        (state) => state.shouldZoomToFiltered
    );
    const setShouldZoomToFiltered = useToolStore(
        (state) => state.setShouldZoomToFiltered
    );
    const getDataOptions = useToolStore((state) => state.getDataOptions);
    const indicatorsMap = useToolStore((state) => state.indicatorsMap);
    const timeRangeMap = useToolStore((state) => state.timeRangeMap);
    const geoLevel = useToolStore((state) => state.geoLevel);

    const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');

    useTitle('Tabell');

    const dataQuery = useDataQuery(getDataOptions(), indicatorsMap);

    if (!dataQuery.data) return <Spinner loading />;

    const { points, options } = dataQuery.data;

    let filteredPoints = points.filter((d) => {
        return featureIsIncludedInCurrentFilter(d, featureFilter);
    });

    if (showOnlySelected && selectedFeatures.length > 0) {
        filteredPoints = filteredPoints.filter((d) => {
            return selectedFeatures.includes(d.properties?.id);
        });
    }

    if (query) {
        filteredPoints = filteredPoints.filter((d) => {
            return (
                d.properties?.kommunnamn.toLowerCase().includes(query) ||
                d.properties?.[options.geoLevel].toLowerCase().includes(query)
            );
        });
    }

    const showOnlySelectedDisabled = selectedFeatures.length === 0;

    return (
        <div>
            <div className="flex flex-row gap-12 items-center p-6">
                <Link
                    to="/"
                    search={search}
                    className="flex items-center btn btn-sm btn-circle btn-primary"
                >
                    <CloseIcon fill="white" />
                </Link>
                <div className="flex flex-row gap-12 items-center justify-center  w-full ">
                    <div>
                        <input
                            type="text"
                            placeholder={t('search') as string}
                            className="input input-sm input-bordered w-full max-w-sm"
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value.toLowerCase());
                            }}
                        />
                    </div>

                    <div className="flex gap-3">
                        <FilterFeatures
                            featureFilter={featureFilter}
                            onChangeFilter={setFeatureFilter}
                            onToggleZoomToFiltered={() => {
                                setShouldZoomToFiltered(!shouldZoomToFiltered);
                            }}
                            zoomToFiltered={shouldZoomToFiltered}
                            points={points}
                            horizontal
                        />
                    </div>
                    <div>
                        <div
                            className={
                                showOnlySelectedDisabled
                                    ? ' tooltip tooltip-left tooltip-warning text-warning-content'
                                    : ''
                            }
                            data-tip={t('inga-punkter-markerade')}
                        >
                            <label
                                className={`flex flex-row gap-2 ${
                                    showOnlySelectedDisabled
                                        ? 'cursor-not-allowed'
                                        : 'cursor-pointer'
                                } text-sm items-center`}
                            >
                                <input
                                    type="checkbox"
                                    checked={
                                        showOnlySelected &&
                                        selectedFeatures.length > 0
                                    }
                                    disabled={showOnlySelectedDisabled}
                                    onChange={() =>
                                        setShowOnlySelected(!showOnlySelected)
                                    }
                                    className="checkbox checkbox-xs checkbox-primary"
                                />
                                {t('show-selected-only')}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <Table
                dataFeatures={filteredPoints}
                dataOptions={options}
                geoLevel={geoLevel}
                timeRangeMap={timeRangeMap}
                selectedFeatures={selectedFeatures}
                featureFilter={featureFilter}
                onSelectFeature={(_selectedFeatures) => {
                    setSelectedFeatures(_selectedFeatures);
                }}
            />
        </div>
    );
}

export default TablePage;
