import { Suspense, lazy, useEffect, useRef } from 'react';
import { useDataQuery } from '../../hooks/use-data.hook';
import { ApiConfig, DataPackage, Indicator } from '../../types';
import { ColorLegend } from '../ColorLegend';
import { TimeLine } from '../TimeLine';
import { ViewPicker } from '../ViewPicker';

import { getRouteApi } from '@tanstack/react-router';
import { Feature } from 'geojson';
import { useShallow } from 'zustand/shallow';
import { TRANSITION_DURATION_MS, GEO_LEVEL_NAME_KEYS } from '../../constants';
import { getMinimumTimerange } from '../../get-minimumtimerange';
import { featureIsIncludedInCurrentFilter } from '../../helpers/filter';
import { useTitle } from '../../hooks/use-title.hook';
import { usePlayerStore } from '../../player.store';
import { Spinner } from '../Spinner';
import { Tooltip } from '../Viz/Tooltip';
import { Menu } from './Menu';
import { Options } from './Options';
import { useToolStore } from './tool.store';
import { ClearSelectionButton, MapTypeSelector } from '../Viz/helpers';
import { FuzzySearch } from '../FuzzySearch/FuzzySearch';
import { YearBadge } from '../YearBadge/YearBadge';

const routeApi = getRouteApi('/');

const VizScatter = lazy(() => import('../Viz/VizScatter'));
const VizMap = lazy(() => import('../Viz/VizMap'));

type Props = {
    config: ApiConfig;
    indicators: Indicator[];
};

export function Tool(props: Props) {
    useTitle('Huddingeanalysen');

    const search = routeApi.useSearch();
    const searchRef = useRef(search);
    searchRef.current = search;

    const toolStore = useToolStore(
        useShallow((state) => ({
            activeFeatureId: state.activeFeatureId,
            indicatorsMap: state.indicatorsMap,
            setActiveFeatureId: state.setActiveFeatureId,
            setTooltipFeatures: state.setTooltipFeatures,
            timeRangeMap: state.timeRangeMap,
            tooltipFeatures: state.tooltipFeatures,
            setColorType: state.setColorType,
            setScaleType: state.setScaleType,
            colorScheme: state.colorScheme,
            setColorScheme: state.setColorScheme,
            colorType: state.colorType,
            scaleType: state.scaleType,
            selectedFeatures: state.selectedFeatures,
            clearSelectedFeatures: state.clearSelectedFeatures,
            featureFilter: state.featureFilter,
            shouldZoomToFiltered: state.shouldZoomToFiltered,
            mapType: state.mapType,
            view: state.view,
            setView: state.setView,
            animationBuster: state.animationBuster,
            setVar: state.setVar,
            getDataOptions: state.getDataOptions,
            indicators: state.indicators,
            setIndicators: state.setIndicators,
            toggleFeatureSelection: state.toggleFeatureSelection,
        }))
    );

    const playerStore = usePlayerStore(
        useShallow((state) => ({
            intValue: state.intValue,
            initialize: state.initialize,
            state: state.state,
            animationBuster: state.animationBuster,
            pause: state.pause,
        }))
    );

    const dataQuery = useDataQuery(
        toolStore.getDataOptions(),
        toolStore.indicatorsMap
    );

    useEffect(() => {
        if (!dataQuery.data) {
            return;
        }

        const timeRange = getMinimumTimerange({
            geoLevel: dataQuery.data.options.geoLevel,
            indicators: Object.values(dataQuery.data.options.indicators).filter(
                (f) => f
            ) as Indicator[],
            timeRangeMap: toolStore.timeRangeMap,
        });

        playerStore.initialize({
            min: timeRange[0],
            max: timeRange[1],
            duration: TRANSITION_DURATION_MS * (timeRange[1] - timeRange[0]),
            startValue: timeRange[0],
        });
    }, [dataQuery.data]);

    const kommunQuery = useDataQuery(
        {
            ...toolStore.getDataOptions(),
            geoLevel: 'kommun',
        },
        toolStore.indicatorsMap
    );

    useEffect(() => {
        const handleGlobalClick = () => {
            const { activeFeatureId, toggleFeatureSelection } =
                useToolStore.getState();

            if (!activeFeatureId) return;

            toggleFeatureSelection(activeFeatureId);
        };

        window.addEventListener('click', handleGlobalClick);

        return () => {
            window.removeEventListener('click', handleGlobalClick);
        };
    }, []);

    if (dataQuery.error) {
        return (
            <div className="fixed inset-0 grid place-items-center">
                <div className="flex flex-col gap-1">
                    Oops!
                    <a href="/" className="link-secondary">
                        reset
                    </a>
                </div>
            </div>
        );
    }

    if (!dataQuery.data) {
        return <Spinner loading={true} />;
    }

    const timeRange = getMinimumTimerange({
        geoLevel: dataQuery.data.options.geoLevel,
        indicators: Object.values(dataQuery.data.options.indicators).filter(
            (f) => f
        ) as Indicator[],
        timeRangeMap: toolStore.timeRangeMap,
    });

    const dataPackages = props.config.dataPackages;

    const selectedDataPackage =
        dataPackages.find((datapackage) => {
            return (
                toolStore.indicators?.x === datapackage?.x &&
                toolStore.indicators?.y === datapackage?.y &&
                toolStore.indicators?.r === datapackage?.r &&
                (!toolStore.indicators?.c ||
                    toolStore.indicators?.c === datapackage?.c)
            );
        }) ?? null;

    function handleSetSelectedDatapackage(newPackage: DataPackage) {
        const newPackageMissingIndicators =
            !newPackage.x || !newPackage.y || !newPackage.r;
        const indicatorsMapMissingIndicators = [
            newPackage.x,
            newPackage.y,
            newPackage.r,
        ].some((d) => !toolStore.indicatorsMap[d]);

        if (newPackageMissingIndicators || indicatorsMapMissingIndicators) {
            console.error(
                `Missing indicators in newPackage:\n${JSON.stringify(
                    newPackage,
                    null,
                    4
                )}\n\nor indicatorsMap:\n${JSON.stringify(
                    toolStore.indicatorsMap,
                    null,
                    4
                )}`
            );
            return;
        }

        playerStore.pause();

        toolStore.setIndicators({
            x: newPackage.x,
            y: newPackage.y,
            r: newPackage.r,
            c: newPackage.c,
        });
    }

    let { points, polygons, options } = dataQuery.data;

    const handleTooltip = ({ object }: { object: Feature }) => {
        if (!object) return null;
        if (
            !featureIsIncludedInCurrentFilter(object, toolStore.featureFilter)
        ) {
            return null;
        }

        const kommunPoint = kommunQuery.data?.points.find((kommun) => {
            return kommun.properties?.KOM_KOD === object.properties?.kommun;
        });
        return { hovered: object, kommun: kommunPoint };
    };

    const handleHover = (id: string | null) => {
        const currentId = toolStore.activeFeatureId;
        if (id !== currentId) {
            toolStore.setActiveFeatureId(id);
            const object = points.find((d) => d.properties?.id === id);
            if (object) {
                const tooltipFeatures = handleTooltip({ object });
                if (tooltipFeatures) {
                    toolStore.setTooltipFeatures(tooltipFeatures);
                }
            }
        }
    };

    const scaleType = options.indicators.cVar?.scaleType ?? toolStore.scaleType;
    const colorType = options.indicators.cVar?.colorType ?? toolStore.colorType;

    const shouldShowMap =
        toolStore.view === 'map' || toolStore.view === 'bubble_and_map';
    const shouldShowScatter =
        toolStore.view === 'bubble' || toolStore.view === 'bubble_and_map';

    return (
        <div className="h-full p-3 bg-base-300 flex flex-col gap-3 overflow-x-hidden">
            <div className="flex flex-row gap-3 flex-1 h-full min-h-0 relative">
                <Menu
                    indicators={props.indicators ?? []}
                    config={props.config}
                    dataPackages={dataPackages}
                    selectedDataPackage={selectedDataPackage}
                    timeRange={timeRange}
                    dataOptions={dataQuery.data.options}
                    data={dataQuery.data}
                    onSetSelectedDatapackage={handleSetSelectedDatapackage}
                    onClearSelectedFeatures={toolStore.clearSelectedFeatures}
                    points={points}
                />

                <div className="grid grid-cols-1 grid-rows-[1fr_auto] flex-1 gap-3">
                    {/* Main visualization */}
                    <div
                        className={` h-full flex-1 rounded-lg grid grid-cols-2 grid-rows-1 relative overflow-hidden`}
                    >
                        {toolStore.activeFeatureId &&
                            toolStore.tooltipFeatures && (
                                <div className="absolute right-1 z-20 bottom-2 pointer-events-none">
                                    <Tooltip
                                        tooltipFeatures={
                                            toolStore.tooltipFeatures
                                        }
                                        geoLevel={
                                            dataQuery.data.options.geoLevel
                                        }
                                        dataOptions={dataQuery.data.options}
                                        year={playerStore.intValue}
                                    />
                                </div>
                            )}
                        <div className="absolute left-1/2 -translate-x-1/2 bottom-16 z-10 pointer-events-none">
                            <YearBadge year={playerStore.intValue} />
                        </div>
                        <Suspense fallback={<Spinner loading />}>
                            {shouldShowScatter && (
                                <div
                                    className="relative h-full bg-white"
                                    style={{
                                        gridColumn:
                                            toolStore.view === 'bubble'
                                                ? 'span 2'
                                                : 'span 1',
                                    }}
                                >
                                    <VizScatter
                                        key={toolStore.view}
                                        dataOptions={options}
                                        dataFeatures={points}
                                        year={playerStore.intValue}
                                        selectedFeatures={
                                            toolStore.selectedFeatures
                                        }
                                        onClearSelection={
                                            toolStore.clearSelectedFeatures
                                        }
                                        animationBuster={Math.max(
                                            playerStore.animationBuster,
                                            toolStore.animationBuster
                                        )}
                                        featureFilter={toolStore.featureFilter}
                                        setActiveFeatureId={handleHover}
                                    />
                                </div>
                            )}
                            {shouldShowMap && (
                                <div
                                    className="relative h-full bg-white"
                                    style={{
                                        gridColumn:
                                            toolStore.view === 'map'
                                                ? 'span 2'
                                                : 'span 1',
                                    }}
                                >
                                    <VizMap
                                        key={toolStore.view}
                                        dataOptions={options}
                                        dataFeatures={points}
                                        polygons={polygons}
                                        kommunData={kommunQuery.data}
                                        year={playerStore.intValue}
                                        selectedFeatures={
                                            toolStore.selectedFeatures
                                        }
                                        onClearSelection={
                                            toolStore.clearSelectedFeatures
                                        }
                                        viewStateInUrl={true}
                                        animationBuster={Math.max(
                                            playerStore.animationBuster,
                                            toolStore.animationBuster
                                        )}
                                        featureFilter={toolStore.featureFilter}
                                        zoomToFiltered={
                                            toolStore.shouldZoomToFiltered
                                        }
                                        mapType={toolStore.mapType}
                                        colorScale={toolStore.colorScheme}
                                        colorType={colorType}
                                        scaleType={scaleType}
                                        setActiveFeatureId={handleHover}
                                        controls={<MapTypeSelector />}
                                    />
                                </div>
                            )}
                        </Suspense>
                    </div>
                    <div className="flex items-center gap-3">
                        <FuzzySearch
                            data={points.filter((point) =>
                                featureIsIncludedInCurrentFilter(
                                    point,
                                    toolStore.featureFilter
                                )
                            )}
                            onSelect={(id) => {
                                console.log(id);
                                toolStore.toggleFeatureSelection(id);
                            }}
                            searchKey={GEO_LEVEL_NAME_KEYS[options.geoLevel]}
                            selectedFeatures={toolStore.selectedFeatures}
                        />
                        {toolStore.selectedFeatures.length > 0 && (
                            <ClearSelectionButton
                                onClick={toolStore.clearSelectedFeatures}
                            />
                        )}
                        <div className="flex-1 flex justify-center">
                            <ViewPicker
                                view={toolStore.view}
                                onSetView={toolStore.setView}
                            />
                        </div>
                        <Options />
                    </div>
                </div>
            </div>
            <div className="flex gap-3">
                <div className="bg-base-100 rounded-lg flex-1">
                    <TimeLine key={timeRange.join('-')} timeRange={timeRange} />
                </div>
                {toolStore.indicators?.c && (
                    <div className="bg-base-100 rounded-lg p-3">
                        <ColorLegend
                            markValue={
                                toolStore.tooltipFeatures?.hovered?.properties
                                    ?.values[options.indicators.cVar?.id ?? ''][
                                    playerStore.intValue
                                ]
                            }
                            showHeader={false}
                            cVar={options.indicators.cVar}
                            data={points}
                            featureFilter={toolStore.featureFilter}
                            zoomToFiltered={toolStore.shouldZoomToFiltered}
                            colorScheme={toolStore.colorScheme}
                            colorType={colorType}
                            scaleType={scaleType}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
