import { Link, getRouteApi } from '@tanstack/react-router';
import { CurrentView } from '../../types';
import { useTranslation } from 'react-i18next';

const routeApi = getRouteApi('/');

export function ViewPicker(props: {
    view: CurrentView;
    onSetView: (newView: CurrentView) => void;
}) {
    const { t } = useTranslation();
    const search = routeApi.useSearch();

    return (
        <div className="tabs tabs-boxed tabs-xs xl:tabs-md flex flex-wrap items-center flex-col md:flex-row justify-start md:justify-center whitespace-nowrap">
            <button
                onClick={() => props.onSetView('bubble')}
                className={`tab static ${
                    props.view === 'bubble'
                        ? 'tab-active'
                        : 'text-base-content/75'
                }`}
            >
                {t('chart')}
            </button>
            <button
                onClick={() => props.onSetView('bubble_and_map')}
                className={`tab static  ${
                    props.view === 'bubble_and_map'
                        ? 'tab-active'
                        : 'text-base-content/75'
                }`}
            >
                {t('chart-and-map')}
            </button>
            <button
                onClick={() => props.onSetView('map')}
                className={`tab static  ${
                    props.view === 'map' ? 'tab-active' : 'text-base-content/75'
                }`}
            >
                {t('map')}
            </button>
            <button className={`tab static text-base-content/75`}>
                <Link to="/table" search={search}>
                    {t('table')}
                </Link>
            </button>
        </div>
    );
}
