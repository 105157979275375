import { SVGProps } from 'react';

interface SizeIconProps extends SVGProps<SVGSVGElement> {
    size?: number;
}

export function SizeIcon({ size = 24, ...props }: SizeIconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...props}
        >
            <circle cx="14" cy="12" r="8" />
            <circle cx="6" cy="12" r="4" />
        </svg>
    );
} 