import { z } from 'zod';

const geoLevelSchema = z.union([
    z.literal('deso'),
    z.literal('regso'),
    z.literal('kommun'),
    z.literal('nyko6'),
]);

export const searchSchema = z.object({
    geoLevel: geoLevelSchema.catch('regso'),
    view: z
        .union([
            z.literal('bubble'),
            z.literal('bubble_and_map'),
            z.literal('map'),
        ])
        .catch('bubble_and_map'),
    mapType: z
        .union([z.literal('choropleth'), z.literal('bubbles')])
        .catch('bubbles'),
    featureFilter: z.array(z.string()).catch(['Huddinge']),
    selectedFeatures: z.array(z.string()).catch([]),
    zoomToFiltered: z.boolean().catch(true),
    scaleType: z
        .union([z.literal('naturalbreaks'), z.literal('linear')])
        .catch('naturalbreaks'),
    colorType: z
        .union([z.literal('sequential'), z.literal('diverging')])
        .catch('sequential'),
    indicators: z
        .object({
            x: z.string(),
            y: z.string(),
            r: z.string(),
            c: z.string().nullable().optional(),
        })
        .optional(),
    year: z.number().optional(),
    animationBuster: z.number().optional(),
    colorScale: z.string().optional(),
    lon: z.number().optional(),
    lat: z.number().optional(),
    zoom: z.number().optional(),
});

export type Search = z.infer<typeof searchSchema>;

export const searchSchemaOptional = z.object({
    geoLevel: geoLevelSchema.optional(),
    view: z
        .union([
            z.literal('bubble'),
            z.literal('bubble_and_map'),
            z.literal('map'),
        ])
        .optional(),
    mapType: z
        .union([z.literal('choropleth'), z.literal('bubbles')])
        .optional(),
    featureFilter: z.array(z.string()).optional(),
    selectedFeatures: z.array(z.string()).optional(),
    zoomToFiltered: z.boolean().optional(),
    scaleType: z
        .union([z.literal('naturalbreaks'), z.literal('linear')])
        .optional(),
    colorType: z
        .union([z.literal('sequential'), z.literal('diverging')])
        .optional(),
    indicators: z
        .object({
            x: z.string(),
            y: z.string(),
            r: z.string(),
            c: z.string().nullable(),
        })
        .optional(),
    year: z.number().optional(),
    animationBuster: z.number().optional(),
    colorScale: z.string().optional(),
    lon: z.number().optional(),
    lat: z.number().optional(),
    zoom: z.number().optional(),
});

export type SearchOptional = z.infer<typeof searchSchemaOptional>;

