import { max, min } from 'd3-array';

import type { TimeRangeMap, Indicator, GeoLevel, TimeRange } from './types';

export function getMinimumTimerange(options: {
    indicators: Indicator[];
    geoLevel: GeoLevel;
    timeRangeMap: TimeRangeMap;
}): TimeRange['timeRange'] {
    const extents = Object.values(options.indicators)
        .map((i) => {
            if (!i) return null;
            return options.timeRangeMap[i.id][options.geoLevel];
        })
        .filter((e) => e !== null || e !== undefined);

    const minYear = max(extents.map((e) => e?.timeRange[0] ?? -Infinity));

    const maxYear = min(extents.map((e) => e?.timeRange[1] ?? Infinity));

    if (!minYear || !maxYear) {
        throw new Error('Failed to get min/max of timeRange.')
    } 

    const result: [number, number] = [minYear, maxYear];

    if(result.some(v => Math.abs(v) === Infinity)){
        throw new Error('Failed to get min/max of timeRange.')
    }
    
    return result
}
