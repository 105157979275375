import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Indicator } from '../../types';
import { IndicatorDialog } from './IndicatorDialog';
import { Portal } from '../Portal';

export function FileSelect2(props: {
    indicators: Indicator[];
    label: string;
    value: string;
    onChange: (value: string) => void;
    labelIcon?: JSX.Element;
    indicatorsMap: Record<string, Indicator>;
    tooltip: string;
    tiny?: boolean;
}) {
    const { i18n } = useTranslation();
    const [shouldShowModal, setShouldShowModal] = useState(false);

    let activeIndicator: Indicator;
    if (props.value === 'kommun') {
        activeIndicator = {
            id: 'kommun',
            label: { sv: 'Kommun' },
            subLabel: { sv: '' },
            show: true,
            unitFormat: 'st',
            urls: {
                deso: '',
                regso: '',
                kommun: '',
                nyko6: '',
            },
            valueKey: '',
            category: { sv: 'Administration' },
            description: { sv: '' },
        };
    } else {
        activeIndicator = props.indicatorsMap[props.value];
    }

    const handleClick = (e: MouseEvent) => {
        setShouldShowModal(true);
    };

    return (
        <>
            {props.tiny && (
                <div className="tooltip  tooltip-right w-full" data-tip={props.label}>
                    <button
                        onClick={handleClick}
                        className={`btn btn-sm btn-primary grid place-items-center w-full`}
                    >
                        {props.labelIcon}
                    </button>
                </div>
            )}
            {!props.tiny && (
                <div className="grid grid-cols-[40px_1fr] gap-2">
                    {props.labelIcon && (
                        <div className="grid place-items-center select-label text-right font-bold capitalize">
                            {props.labelIcon}
                        </div>
                    )}
                    {!props.labelIcon && (
                        <div className="select-label text-right font-bold capitalize">
                            {props.label}
                        </div>
                    )}
                    <div className="">
                        <button
                            type="button"
                            className={`
                        text-left w-full rounded-md tooltip tooltip-top tooltip-secondary
                        hover:bg-primary hover:text-primary-content hover:rounded-md hover:shadow-lg
                        px-3 py-1`}
                            data-tip={props.tooltip}
                            onClick={handleClick}
                        >
                            <div className="text-xs">
                                {activeIndicator.category?.[i18n.language] ??
                                    activeIndicator.category?.['sv']}
                            </div>
                            <div className="text-base font-light">
                                {activeIndicator.label[i18n.language] ??
                                    activeIndicator.label['sv']}
                            </div>
                            <div className="text-sm font-bold">
                                {activeIndicator.subLabel[i18n.language] ??
                                    activeIndicator.subLabel['sv']}
                            </div>
                        </button>
                    </div>
                </div>
            )}

            {shouldShowModal && (
                <Portal>
                    <div className="fixed inset-0 bg-black bg-opacity-20 isolate grid place-items-center pointer-events-none z-50">
                        <div
                            className="fixed inset-0 pointer-events-auto cursor-pointer"
                            onClick={() => {
                                setShouldShowModal(false);
                            }}
                        />
                        <IndicatorDialog
                            indicators={props.indicators}
                            value={props.value}
                            onChange={(value) => {
                                props.onChange(value);
                                setShouldShowModal(false);
                            }}
                            onClose={() => {
                                setShouldShowModal(false);
                            }}
                            tooltip={props.tooltip}
                            indicatorsMap={props.indicatorsMap}
                            activeIndicator={activeIndicator}
                        />
                    </div>
                </Portal>
            )}
        </>
    );
}
