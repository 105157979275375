import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useShallow } from 'zustand/shallow';
import { useIsLoggedIn } from '../components/AuthProvider';
import { Spinner } from '../components/Spinner';
import { Tool } from '../components/Tool';
import { useToolStore } from '../components/Tool/tool.store';
import { configLoader } from '../configLoader';
import { ApiConfig, Indicator } from '../types';
import { searchSchemaOptional } from '../zod';

export const Route = createFileRoute('/')({
    validateSearch: (search) => searchSchemaOptional.parse(search),
    component: Index,
});

function Index() {
    const { isLoggedIn } = useIsLoggedIn();
    const search = Route.useSearch();

    const { data: indicators, isLoading: indicatorsIsLoading } = useQuery<
        Indicator[]
    >({
        queryKey: ['indicators', isLoggedIn],
        queryFn: async () => {
            const request = await fetch(
                import.meta.env.VITE_API_URL + '/api/v1/data/indicators',
                {
                    credentials: 'include',
                }
            );
            const json = await request.json();
            return json;
        },
    });

    const { data: config, isLoading: configIsLoading } = useQuery<ApiConfig>({
        queryKey: ['config', isLoggedIn],
        queryFn: async () => {
            const { config } = await configLoader(isLoggedIn);

            return config;
        },
    });

    const toolStore = useToolStore(
        useShallow((state) => ({
            initialize: state.initialize,
            isInitialized: state.isInitialized,
        }))
    );

    useEffect(() => {
        if (toolStore.isInitialized || !indicators || !config) return;
        toolStore.initialize({ indicators, config, search });
    }, [indicators, config, toolStore.isInitialized]);

    if (
        indicatorsIsLoading ||
        configIsLoading ||
        indicators === undefined ||
        config === undefined ||
        !toolStore.isInitialized
    ) {
        return <Spinner loading={true} />;
    }

    return (
        <div className="h-full">
            <Tool config={config} indicators={indicators} />
        </div>
    );
}
