import { ReactNode } from 'react';
import { HamburgerIcon } from '../Icons/HamburgerIcon';
import { Hero } from './Hero';
import { Nav } from './Nav';

export function ScrollyArticle(props: { children: ReactNode }) {
    return (
        <div className="drawer lg:drawer-open relative">
            <input id="menu-toggle" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content flex flex-col relative">
                {/* Mobile open drawer button */}
                <div className="fixed ml-3 top-3 lg:hidden">
                    <label
                        title="Open menu"
                        htmlFor="menu-toggle"
                        className="btn btn-primary btn-sm drawer-button p-0 w-9 h-9"
                    >
                        <HamburgerIcon size={28} fill="white" />
                    </label>
                </div>

                {/* <!-- Page content here --> */}
                <Hero />

                <div className="px-4 sm:px-12 w-full max-w-6xl mx-auto pt-12 pb-64 h-full">
                    {props.children}
                </div>
            </div>
            <div className="drawer-side lg:border shadow-xl">
                <label
                    title="Close menu"
                    htmlFor="menu-toggle"
                    className="drawer-overlay"
                ></label>
                <div className="p-4 w-60 bg-base-100 h-full">
                    {/* <!-- Sidebar content here --> */}
                    <Nav />
                </div>
            </div>
        </div>
    );
}
