import { getRouteApi } from '@tanstack/react-router';
import { useEffect } from 'react';
import { usePlayerStore } from '../../player.store';
import { PauseIcon, PlayIcon, ReplayIcon } from '../Icons/PlayIcons';
import { TimeSlider } from './TimeSlider';

const routeApi = getRouteApi('/');

type Props = {
    timeRange: number[];
};

export function TimeLine({ timeRange }: Props) {
    const playerStore = usePlayerStore();

    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === ' ') {
                event.preventDefault();
                playerStore.playToggle();
            }

            if (event.key === 'ArrowLeft') {
                event.preventDefault();
                playerStore.decrement();
            }

            if (event.key === 'ArrowRight') {
                event.preventDefault();
                playerStore.increment();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    });

    return (
        <div className="relative inset-0 p-3 pointer-events-none overlay">
            <div className="flex flex-col gap-3">
                <div className="flex flex-col items-center">
                    <div className="flex items-center gap-2 w-full">
                        <button
                            className="btn btn-sm btn-circle btn-neutral fill-white"
                            onClick={() => playerStore.playToggle()}
                        >
                            {playerStore.state === 'stopped' && <ReplayIcon />}
                            {playerStore.state === 'paused' && <PlayIcon />}
                            {playerStore.state === 'playing' && <PauseIcon />}
                        </button>
                        <TimeSlider
                            min={timeRange[0]}
                            max={timeRange[1]}
                            value={playerStore.value}
                            onChange={(value) => {
                                playerStore.setValue(value);
                            }}
                            step={0.01}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
